import { useCallback, useMemo, useRef } from 'react';
import debounce from 'lodash.debounce';

export const debounceTime = 50;

/**
 * This hook is useful when you want to track focus/blur events for component as a whole.
 * When focus switches from A to B, the blur of A triggers before the focus of B.
 * So, by merging both of these events into a single debounced function, we can essentially ignore
 * the change of focus, assuming both A and B are using the returned focus and blur functions.
 *
 * @param onFocus memoized function to be called on focus
 * @param onBlur memoized function to be called on blur
 * @returns memoized object of focus and blur functions to be used instead of the passed in functions
 */
const useGroupFocus = (onFocus?: () => void, onBlur?: () => void) => {
  const hasFocus = useRef(false);

  const handleFocusChange = useCallback(
    (action: 'blur' | 'focus') => {
      if (action === 'blur' && onBlur) {
        onBlur();
      } else if (action === 'focus' && onFocus && !hasFocus.current) {
        onFocus();
      }
      hasFocus.current = action === 'focus';
    },
    [onBlur, onFocus]
  );
  const debouncedHandleFocusChange = useMemo(() => debounce(handleFocusChange, debounceTime), [handleFocusChange]);

  const focus = useMemo(() => () => debouncedHandleFocusChange('focus'), [debouncedHandleFocusChange]);
  const blur = useMemo(() => () => debouncedHandleFocusChange('blur'), [debouncedHandleFocusChange]);
  const ret = useMemo(() => ({ focus, blur }), [focus, blur]);

  return ret;
};

export default useGroupFocus;
