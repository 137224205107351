import classNames from 'classnames';
import { ComponentProps, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useGroupFocus } from '@alltrails/core';
import Clear from '../../icons/Clear';
import Search from '../../icons/Search';
import IconButton from '../IconButton';
import { Size } from '../../types';
import styles from './styles/styles.module.scss';

export type SearchInputProps = {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  /**
   * An override for default "clear" functionality of the icon button in the input.
   */
  iconButtonProps?: Pick<ComponentProps<typeof IconButton>, 'icon' | 'onClick' | 'title' | 'testId'> & { persistIcon?: boolean };
  onBlur?: () => void;
  onChange: (value: string) => void;
  onClick?: () => void;
  onFocus?: () => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
  testId: string;
  value?: string;
  variant?: 'default' | 'subtle';
  size?: Size<'sm' | 'md'>;
};

const SearchInput = ({
  autoFocus,
  className,
  disabled,
  iconButtonProps,
  onBlur,
  onChange,
  onClick,
  onFocus,
  onKeyDown,
  placeholder,
  testId,
  value,
  variant = 'default',
  size = 'md'
}: SearchInputProps): JSX.Element => {
  const intl = useIntl();
  const inputElement = useRef<HTMLInputElement>(null);
  const { blur, focus } = useGroupFocus(onFocus, onBlur);

  useEffect(() => {
    if (autoFocus) {
      inputElement.current?.focus();
    }
  }, [autoFocus]);

  const iconButton = useMemo(() => {
    if (value || iconButtonProps?.persistIcon) {
      return (
        <IconButton
          className={styles.inputIconButton}
          icon={iconButtonProps?.icon ?? { Component: Clear }}
          onClick={e => {
            e.stopPropagation();
            if (iconButtonProps?.onClick) {
              iconButtonProps.onClick(e);
            } else {
              onChange('');
              inputElement.current?.focus();
            }
          }}
          onBlur={blur}
          onFocus={focus}
          variant="flat"
          size={size}
          title={iconButtonProps?.title ?? intl.formatMessage({ defaultMessage: 'Clear' })}
          testId={iconButtonProps?.testId ?? `${testId}-clear`}
        />
      );
    }

    return null;
  }, [blur, focus, iconButtonProps, intl, onChange, size, testId, value]);

  return (
    <div className={classNames(styles.container, disabled && styles.disabled, styles[variant], styles[size], className)}>
      <Search color={disabled ? '--color-text-disabled' : '--color-brand-primary'} size="sm" />
      <input
        ref={inputElement}
        className={classNames(styles.input, styles[size])}
        disabled={disabled}
        onChange={e => {
          onChange(e.target.value);
        }}
        onBlur={blur}
        onFocus={focus}
        onKeyDown={onKeyDown}
        onClick={onClick}
        placeholder={placeholder}
        value={value}
        data-testid={testId}
        aria-label={placeholder || intl.formatMessage({ defaultMessage: 'Search input' })}
      />
      {iconButton}
    </div>
  );
};

export default SearchInput;
