import { ComponentProps, KeyboardEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import FocusTrap from 'focus-trap-react';
import SearchInput, { type SearchInputProps } from '@alltrails/denali/components/SearchInput';
import Link from '@alltrails/denali/components/Link';
import { ModalPortal } from '@alltrails/core';
import { OnSelect, RenderResultContent, ResultBase, Results } from '../../types/searchBoxTypes';
import CustomSearchResults from './results/CustomSearchResults';
import styles from './styles/MobileSearch.module.scss';

type MobileSearchProps<T extends ResultBase> = {
  activeId?: string;
  iconButtonProps?: SearchInputProps['iconButtonProps'];
  focusTrapOptions?: ComponentProps<typeof FocusTrap>['focusTrapOptions'];
  onCancel: () => void;
  onChange: (query: string) => void;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  onSelect: OnSelect<T>;
  placeholder: string;
  query?: string;
  renderResultContent: RenderResultContent<T>;
  results: Results<T>;
  testId: string;
  areResultsLoading?: boolean;
};

const MobileSearch = <T extends ResultBase>({
  activeId,
  iconButtonProps,
  focusTrapOptions,
  onCancel,
  onChange,
  onKeyDown,
  onSelect,
  placeholder,
  query = '',
  renderResultContent,
  results,
  testId,
  areResultsLoading
}: MobileSearchProps<T>) => {
  return (
    <ModalPortal>
      <FocusTrap
        active
        // fallbackFocus is used to prevent errors in testing https://github.com/focus-trap/focus-trap-react/issues/91
        focusTrapOptions={{
          fallbackFocus: '#mobile-search',
          initialFocus: false,
          ...focusTrapOptions
        }}
      >
        <div className={styles.container}>
          <div className={styles.inputContainer} id="mobile-search">
            <SearchInput
              autoFocus
              iconButtonProps={iconButtonProps}
              onChange={onChange}
              onKeyDown={onKeyDown}
              placeholder={placeholder}
              testId={testId}
              value={query}
            />
            <Link className={styles.cancel} onClick={onCancel} noUnderline>
              <FormattedMessage defaultMessage="Cancel" />
            </Link>
          </div>
          <CustomSearchResults
            className={styles.results}
            activeId={activeId}
            onSelect={onSelect}
            query={query}
            renderResultContent={renderResultContent}
            results={results}
            areResultsLoading={areResultsLoading}
          />
        </div>
      </FocusTrap>
    </ModalPortal>
  );
};

export default MobileSearch;
