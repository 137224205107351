import { SectionHeading } from '../../../types/searchBoxTypes';
import styles from './styles/SectionHeader.module.scss';

export type SectionHeaderProps = {
  heading: SectionHeading;
};

const SectionHeader = ({ heading }: SectionHeaderProps) => <div className={styles.header}>{heading.text}</div>;

export default SectionHeader;
