import Icon from '../components/Icon';

const Search = Icon(
  'search',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 10.875a7.125 7.125 0 1 1 14.25 0 7.125 7.125 0 0 1-14.25 0Zm7.125-8.625a8.625 8.625 0 1 0 5.546 15.231l4.049 4.05a.75.75 0 0 0 1.06-1.061l-4.049-4.05a8.625 8.625 0 0 0-6.606-14.17Z"
    />
  </>
);

export default Search;
