import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import LoadingDots from '@alltrails/denali/components/LoadingDots';
import { OnSelect, RenderResultContent, ResultBase, Results } from '../../../types/searchBoxTypes';
import SectionHeader from './SectionHeader';
import ResultItem from './ResultItem';
import styles from './styles/CustomSearchResults.module.scss';

type CustomSearchResultsProps<T extends ResultBase> = {
  activeId?: string;
  areResultsLoading?: boolean;
  className?: string;
  onSelect: OnSelect<T>;
  preventScrollOnResultSelection?: boolean;
  query: string;
  results: Results<T>;
  renderResultContent: RenderResultContent<T>;
};

const CustomSearchResults = <T extends ResultBase>({
  activeId,
  areResultsLoading,
  className,
  onSelect,
  preventScrollOnResultSelection,
  query,
  results,
  renderResultContent
}: CustomSearchResultsProps<T>) => {
  const content = useMemo(() => {
    if (results.length === 0 && !query) {
      return null;
    }
    if (results.length) {
      return results.map((result, index) => {
        if ('text' in result) {
          return <SectionHeader key={result.text} heading={result} />;
        }
        return (
          <ResultItem
            className={result.containerClassName}
            key={result.id}
            isActive={activeId === result.id}
            onClick={() => onSelect(result as T, { index, query })}
            preventAutoScroll={preventScrollOnResultSelection}
          >
            {renderResultContent(result as T)}
          </ResultItem>
        );
      });
    }
    if (areResultsLoading) {
      return (
        <div className={styles.loadingState}>
          <LoadingDots testId="search-loading" />
        </div>
      );
    }
    return (
      <div className={styles.nullState}>
        <div className={styles.nullHeader}>
          <FormattedMessage defaultMessage="No results" />
        </div>
        <div className={styles.nullSubtext}>
          <FormattedMessage defaultMessage="We couldn't find anything matching {query}" values={{ query }} />
        </div>
      </div>
    );
  }, [activeId, areResultsLoading, onSelect, preventScrollOnResultSelection, query, renderResultContent, results]);

  return content ? (
    // prevent blurring the input when clicking inside the dropdown
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={className} onMouseDown={e => e.preventDefault()}>
      {content}
    </div>
  ) : null;
};

export default CustomSearchResults;
