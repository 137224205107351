import { ResultBase, Results } from '../types/searchBoxTypes';

export const stripSectionHeadings = <T extends ResultBase>(results: Results<T>) => results.filter(result => 'id' in result) as T[];

export const getFirstItem = <T extends ResultBase>(results: Results<T>) => {
  const items = stripSectionHeadings(results);
  if (items.length) {
    return items[0];
  }
  return undefined;
};

export const getNewActiveItem = <T extends ResultBase>(results: Results<T>, activeItem: T | undefined, direction: 'prev' | 'next') => {
  if (!activeItem) {
    return getFirstItem(results);
  }
  const items = stripSectionHeadings(results);
  const activeIndex = items.findIndex(result => result.id === activeItem.id);
  if (activeIndex === -1) {
    return undefined;
  }
  if (items.length === 1) {
    return activeItem;
  }
  const remainingItems = [...items.slice(activeIndex + 1), ...items.slice(0, activeIndex)];
  return (direction === 'prev' ? remainingItems.reverse() : remainingItems)[0];
};
