import Icon from '../components/Icon';

const Filter = Icon(
  'filter',
  <>
    <path
      fillRule="evenodd"
      d="M14 5.25a.75.75 0 0 1 .75.75v1.25H21a.75.75 0 0 1 0 1.5h-6.25V10a.75.75 0 0 1-1.5 0V6a.75.75 0 0 1 .75-.75M2.25 8A.75.75 0 0 1 3 7.25h7a.75.75 0 0 1 0 1.5H3A.75.75 0 0 1 2.25 8M8 13.25a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0v-1.25H3a.75.75 0 0 1 0-1.5h4.25V14a.75.75 0 0 1 .75-.75M11.25 16a.75.75 0 0 1 .75-.75h9a.75.75 0 0 1 0 1.5h-9a.75.75 0 0 1-.75-.75"
      clipRule="evenodd"
    />
  </>
);

export default Filter;
