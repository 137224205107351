import { PropsWithChildren, useEffect, useRef } from 'react';
import classNames from 'classnames';
import styles from './styles/ResultItem.module.scss';

export type ResultItemProps = {
  className?: string;
  isActive?: boolean;
  onClick?: () => void;
  preventAutoScroll?: boolean;
};

const ResultItem = ({ children, className, isActive, onClick, preventAutoScroll }: PropsWithChildren<ResultItemProps>) => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isActive && !preventAutoScroll) {
      container.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isActive, preventAutoScroll]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div ref={container} className={classNames(styles.container, { [styles.active]: isActive }, className)} onClick={onClick}>
      {children}
    </div>
  );
};

export default ResultItem;
